import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from '../../services/api.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

    public email = '';
    public status: string;
    public error = false;

    constructor(private apiService: ApiService) {
    }

    ngOnInit() {
    }

    public send(): void {
        if (!this.email) {
            return;
        }
        // console.log(this.email);
        this.status = 'saving';
        this.error = false;
        this.apiService.addSubscriber(this.email).subscribe(result => {
            this.email = '';
            this.status = 'saved';
            // console.log('success');
            setTimeout(() => {
                this.status = '';
            }, 5000);
        }, error1 => {
            // console.log(error1);
            this.error = true;
            this.status = '';
            setTimeout(() => {
                this.status = '';
            }, 5000);
        });
    }

    public keyup($event): void {
        this.status = '';
        // noinspection JSDeprecatedSymbols
        if ($event.keyCode === 13) {
            this.send();
        }
    }

    public validateEmail(email) {
        const re = new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|' +
            '(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\' +
            '.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');
        return re.test(String(email).toLowerCase());
    }
}
