import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { EventModel } from '../models/event.model';

@Injectable()
export class EventsResolver implements Resolve<any> {

    constructor(protected apiService: ApiService) {
    }

    async resolve(): Promise<EventModel[]> {
        return await this.apiService.getEvents();
    }
}
