<div class="civic-tracks inner-shadow-top">
    <div class="container-fluid text-center component">
        <h1 class="section-title">Civic Tracks</h1>
        <div class="description">
            <p>This is not your ordinary hackathon!</p>
            <div class="row justify-content-center">
                <div class="col-12 col-sm-9 col-md-6">
                    <p>UniHack encourages you to identify a civic issue in our society and find a solution for it. And if you need guidance in your quest to make other people's life easier, we have good news for you! There will be mentors available who can help you better understand the problem you want to solve and steer your solution in the right direction.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="background-buffer"></div>
</div>
