import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ScheduleEntryModel } from '../../models/schedule-entry.model';
import { HeaderMenuHeightService } from '../../services/header-menu-height.service';

@Component({
    selector: 'app-schedule',
    templateUrl: './schedule.component.html',
    styleUrls: ['./schedule.component.scss'],
})
export class ScheduleComponent implements OnInit {

    public schedule: { date: Date; entries: ScheduleEntryModel[] }[];


    constructor(private apiService: ApiService, public heightService: HeaderMenuHeightService) {
        this.GetSchedules();
    }

    ngOnInit() {
    }

    private GetSchedules() {
        this.apiService.getSchedule().subscribe(data => {
            this.prepareSchedule(data);
        }, error1 => {
            console.log('Could not get schedules: ', error1);
        });
    }

    public timeZoneOffset(date: string) {
        //const localTime = new Date().getTimezoneOffset() ;
        const localTime = 0;
        const accualDate = new Date(date);
        return new Date(accualDate.getTime() - localTime * 60000);
    }

    private prepareSchedule(data: ScheduleEntryModel[]): void {
        this.schedule = [];
        for (const entry of data) {
            const dateTime = new Date(entry.startTime);
            const lastItem = this.schedule.length ? this.schedule[this.schedule.length - 1].date : null;

            if (!lastItem || this.getDate(dateTime).valueOf() !== this.getDate(lastItem).valueOf()) {
                this.schedule.push({
                    date: dateTime,
                    entries: [entry],
                });
            } else {
                this.schedule[this.schedule.length - 1].entries.push(entry);
            }
        }
    }

    private getDate(dateTime: Date): Date {
        const date = new Date(dateTime);
        date.setHours(0, 0, 0, 0);
        return date;
    }

    public isHappening(entry: ScheduleEntryModel) {
        const now = new Date();

        return new Date(this.timeZoneOffset(entry.startTime)) <= now && new Date(this.timeZoneOffset(entry.endTime)) > now;
    }
}
