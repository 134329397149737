import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-project-ideas-list',
    templateUrl: './project-ideas-list.component.html',
    styleUrls: ['./project-ideas-list.component.scss'],
})
export class ProjectIdeasListComponent implements OnInit {

    constructor() {
        this.populateIdeas();
    }

    public ideas = [];

    ngOnInit() {
    }

    private populateIdeas() {
        this.ideas = [
            {
                partner: 'FITT',
                ideas: [
                    {
                        title: 'Promoting and Booking System for Events Held in Youth Centers in Timișoara',
                        description: '<p>Starting from the 12th of August, 5 new spaces will be set up in different neighbourhoods ' +
                            'of the city. These spaces will be dedicated to cultural events, such as theatre concerts, ' +
                            'film screenings and various trainings and workshops.</p>',
                    },
                    {
                        title: 'Monitoring and Optimisation System for Current Resource Consumption in the ' +
                            'Timiș County Youth Foundation Building',
                        description: '<p></p>',
                    },
                    {
                        title: 'Data Collection and Analysis System for People Transmitting in and out of Buildings',
                        description: '<p>The system should be able to make recommendations such as which days are better for ' +
                            'organising events, volume of people depending on time intervals and so on, based on the analysis made.</p>',
                    },
                    {
                        title: 'Resource Sharing Application',
                        description: '<p>A platform where people, NGOs, institutions, or private businesses can register with the ' +
                            'resources they can offer for free in order to organise cultural or educational events for the community.</p>' +
                            '<p>Those who are registered have the opportunity to upload resources (equipment, spaces, human ' +
                            'resources, transport, etc.) and at the same time can make a booking request for a specific place / event. ' +
                            'The resource request must be approved - to be sure it is a nonprofit event</p>',
                    },
                ],
            },
            {
                partner: 'Continental',
                ideas: [
                    {
                        title: 'Traffic Jam Map Based on Car Sensors',
                        description: '<p>Many drivers like to report the traffic jam into the app in order to be useful for other ' +
                            'users. This can be done directly by the car using the sensors. In this way we can automatically create ' +
                            'an online traffic jam map.</p>',
                    },
                    {
                        title: 'Driving Profile Manager Using Car Sensors',
                        description: '<p>Have you ever evaluated your own driving style not being subjective? Now is the time to let an ' +
                            'app to do it objectively. This can be done based on the sensors from the vehicle which collect the ' +
                            'driving parameters and send it to an app in order to create your driving profile.</p>',
                    },
                    {
                        title: 'System for Providing Cleaner Air in Cars',
                        description: '<p>Driving in an unpleasant scented area or close to a highly polluting vehicle is not pleasant. ' +
                            'It would be great to know this in advance, in order to avoid the zone or close the windows.</p>',
                    },
                    {
                        title: 'Corner Visibility System',
                        description: '<p>Getting in the intersection without clear and complete visibility can be very risky. We want ' +
                            'to create a system that will solve this problem in situations where the driver\'s view is obstructed.</p>',
                    },
                    {
                        title: 'Smart Parking System',
                        description: '<p>Parking in small parking places can be a challenge. It would be great to get the experience of ' +
                            'the previous drivers who already parked there in order to simplify your parking experience.</p>',
                    },
                    {
                        title: 'Emergency Vehicles Support System',
                        description: '<p>When they are in a mission, every second in traffic counts for the ambulance, firetruck and ' +
                            'police car. A system that will decongest the road in advance and provide a clearer way for the ' +
                            'emergency vehicles could be a solution for smarter cities.</p>',
                    },
                    {
                        title: 'Road Condition Warning System',
                        description: '<p>Dangerous roads are frightening experiences for any driver. We want to adjust the car behavior ' +
                            'in the cases when the road presents a higher risk of accidents due to temporary bad weather conditions.</p>',
                    },
                    {
                        title: 'System for Increasing Safety for Cyclists and Pedestrians',
                        description: '<p>Pedestrians and cyclists are sometime hard to be noticed by drivers, especially during the ' +
                            'night and in bad weather conditions. We want to create a system that will allow pedestrians and ' +
                            'cyclists to be highlighted to the cars around, relative to their presence and position.</p>',
                    },
                    {
                        title: 'Road Slipperiness Detection and Warning System',
                        description: '<p>It is important to know in advance about slippery roads. We want to build a system that ' +
                            'automatically detects and shares this information with other vehicles.</p>',
                    },
                ],
            },
            {
                partner: 'SafeFleet',
                ideas: [
                    {title: 'Elections Candidates Aggregator'},
                    {title: 'City Zoning App'},
                    {title: 'How’s My City Doing?'},
                    {title: 'Smart Laws Search Engine'},
                    {title: 'Volunteering Social Network'},
                    {title: 'Free Parking Spots Estimator'},
                ],
            },
            {
                partner: 'Visma',
                ideas: [
                    {title: 'Green Environment'},
                    {title: 'Tourism'},
                    {title: 'Apps that Solve Administrative Issues (traffic, political, etc)'},
                ],
            },
        ];
    }
}
