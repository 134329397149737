<div class="banner inner-shadow-top" id="dbtop">
    <div class="container vertical-align full-height-vh banner-background-container"
         [ngStyle]="{'padding-top': heightService.getHeightPx}">
        <div class="banner-background"></div>
        <div class="row"></div>
        <div class="row justify-content-around">
            <div class="col-12 col-sm-9 col-md-7">
                <div class=" main-text text-center">
                    Since you were curious enough to get here, we grant you access to some info about UniHack, the
                    newest
                    student hackathon in town!
                </div>
                <div class="d-flex justify-content-center pt-3">
                    <a [routerLink]="['/']">
                        <button class="btn btn-primary orange-button pl-4 pr-4">Enter
                        <img src="assets/images/baseline-lock_open-24px.svg" class="button-image" /></button>
                    </a>
                </div>
            </div>

        </div>
        <div class="row">

        </div>
    </div>
</div>
