import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mlh-badge',
  templateUrl: './mlh-badge.component.html',
  styleUrls: ['./mlh-badge.component.scss'],
})
export class MlhBadgeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
