import { Component } from '@angular/core';

@Component({
  selector: 'app-lucky-wheel',
  templateUrl: './lucky-wheel.component.html',
  styleUrls: ['./lucky-wheel.component.scss']
})
export class LuckyWheelComponent {
  public prizes = [
    { name: "Sticker", id: "sticker", chance: 25, deg: 0, active: true, img: "/assets/wheel/logo_unihack.png" },
    { name: "Pix", id: "pix", chance: 25, deg: 60, active: true, img: "/assets/wheel/pix_unihack.png" },
    { name: "Minge", id: "minge", chance: 25, deg: 120, active: true, img: "/assets/wheel/minge_unihack.png" },
    { name: "Sticker + Pix", id: "sticker-pix", chance: 15, deg: 180, active: true, img: "/assets/wheel/pix_sticker_unihack.png" },
    { name: "Sticker + Minge", id: "sticker-minge", chance: 5, deg: 240, active: true, img: "/assets/wheel/minge_sticker_unihack.png" },
    { name: "Minge + Pix", id: "minge-pix", chance: 5, deg: 300, active: true, img: "/assets/wheel/pix_minge_unihack.png" },
  ];
  private canSpin = true;
  private numberOfSpins = 0;
  private defaultSpinDeg = 2160;
  private defaultSpinVariance = 60;

  /*    constructor(
              private pageTitleService: PageTitleService
      ) {
          pageTitleService.setTitle('Prize Wheel');
          for (let i = 0; i < this.prizes.length; i++) {
              let value = sessionStorage.getItem(this.prizes[i].name)
              if (value == "true") this.prizes[i].active = true;
              else if (value == "false") this.prizes[i].active = false;
          }
      }*/

  toLink(value) {
    return value.toLowerCase().replaceAll(" ", "-");
  }

  getPrize() {
    let total_chance = this.prizes.reduce((total, prize) => prize.active ? total + prize.chance : total, 0);
    let selected_chance = Math.floor(Math.random() * total_chance);
    let prize;
    for (let i = 0; selected_chance > 0; i++) {
      if (this.prizes[i].active) selected_chance -= this.prizes[i].chance;
      if (selected_chance < 0) prize = this.prizes[i];
    }
    return prize;
  }

  timeoutId: any;

  showOverlay(prize) {
    let overlay = document.getElementById("wheel-overlay");
    overlay.querySelector("img").src = prize.img;
    overlay.querySelector("h1").innerHTML = prize.name;
    overlay.style.display = "flex";

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = setTimeout(() => {
      this.hideOverlay();
    }, 5000); // 5000 milliseconds = 5 seconds
  }

  hideOverlay() {
    let overlay = document.getElementById("wheel-overlay");
    if (overlay.style.display !== 'none') {
      overlay.style.display = "none";
    }
    // Clear the timeout when the overlay is hidden
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }
  spin() {
    if (this.canSpin) {
      this.canSpin = false;
      let wheel = document.getElementById("wheel");

      let prize = this.getPrize();

      this.numberOfSpins++;
      // @ts-ignore
      let deg = this.numberOfSpins * this.defaultSpinDeg + prize.deg
          + Math.floor(Math.random() * this.defaultSpinVariance);
      wheel.style.transform = "rotate(" + deg + "deg)";

      setTimeout(() => {
        // @ts-ignore
        this.showOverlay(prize);
        this.canSpin = true;
      }, 3000);
    }
  }

  showMenu() {
    let menu = document.getElementById("wheel-menu");
    let inputs = menu.querySelectorAll("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].checked = this.prizes[i].active;
    }
    menu.style.display = "flex";
  }
  hideMenu() {
    let menu = document.getElementById("wheel-menu");
    let inputs = menu.querySelectorAll("input");
    for (let i = 0; i < inputs.length; i++) {
      this.prizes[i].active = inputs[i].checked;
      sessionStorage.setItem(this.prizes[i].name, inputs[i].checked ? "true" : "false");
    }
    menu.style.display = "none";
  }
}

