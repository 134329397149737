import { Component, OnInit } from '@angular/core';
import { ProjectModel } from '../../models/project.model';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-projects-page',
  templateUrl: './projects-page.component.html',
  styleUrls: ['./projects-page.component.scss'],
})
export class ProjectsPageComponent implements OnInit {

  public projects: ProjectModel[] = [];

  constructor(private actr: ActivatedRoute) {
    this.projects = this.actr.snapshot.data.projects;
  }

  ngOnInit() {
  }
}
