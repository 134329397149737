<div class="component reasons-to-come inner-shadow-top" id="reasons-to-come">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="centered titles">Reasons to come</div>
            </div>
        </div>
        <div class="row no-gutters mb-3 reasons justify-content-center">
            <div *ngFor="let reason of reasons" align="center"
                 class="col-12 col-lg-4 justify-content-center mb-4 d-flex">
                <div class="row align-items-center mx-auto pl-lg-3">
                    <div class="col-lg-auto p-0 mb-sm-3">
                        <div class="bullet-shadow mr-md-3 mb-3 mb-sm-0 d-flex">
                            <div class="bullet">
                                <img src="{{reason.imageSrc}}"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm text-lg-left reason-text">{{reason.text}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
