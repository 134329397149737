import { Injectable } from '@angular/core';
import { PersonDetailsDialogComponent } from '../components/person-details-dialog/person-details-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { PersonModel } from '../models/person.model';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    public isDialogOpen = false;

    constructor(public dialog: MatDialog) {
    }

    public openPersonDetailsDialog(person: PersonModel) {
        this.isDialogOpen = true;
        const dialogRef = this.dialog.open(PersonDetailsDialogComponent, {data: person, autoFocus: false, maxWidth: 800});
        dialogRef.afterClosed().subscribe(() => {
            this.isDialogOpen = false;
        });
    }
}
