import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EventDetailsPageComponent } from './pages/event-details-page/event-details-page/event-details-page.component';
import { HomePageComponent } from './pages/home-page/home-page/home-page.component';
import { PrivacyPolicyPageComponent } from './pages/privacy-policy-page/privacy-policy-page.component';
import { TrackingLandingPageComponent } from './pages/tracking-landing-page/tracking-landing-page.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { PageResolver } from './resolvers/page.resolver';
import { TracksPageComponent } from './pages/tracks-page/tracks-page.component';
import { ScheduleComponent } from './pages/schedule/schedule.component';
import { ProjectIdeasPageComponent } from './pages/project-ideas-page/project-ideas-page.component';
import { AboutFirstEditionComponent } from './pages/about/about-first-edition/about-first-edition.component';
import { SettingsResolver } from './resolvers/settings-resolver.service';
import { ProjectsPageComponent } from './pages/projects-page/projects-page.component';
import { ProjectsResolver } from './resolvers/projects.resolver';
import { EventsPageComponent } from './pages/events-page/events-page.component';
import { EventsResolver } from './resolvers/events.resolver';
import { PeopleResolver } from './resolvers/people.resolver';
import { PeoplePageComponent } from './pages/people-page/people-page.component';
import { LuckyWheelComponent } from './pages/lucky-wheel/lucky-wheel.component';
import { TrackFormComponent } from './pages/track-form/track-form.component';

const routes: Routes = [
    {
        path: '',
        component: HomePageComponent,
        pathMatch: 'full',
        resolve: {entity: PageResolver, settings: SettingsResolver},
        data: {ogMetaType: 'home'},
    },
    {
        path: 'w',
        component: LuckyWheelComponent,
    },
    {
        path: 'feedback',
        component: TrackFormComponent,
        resolve: {entity: PageResolver, settings: SettingsResolver},
        data: {ogMetaType: 'feedback'},
    },
    {
        path: 'about',
        component: EventDetailsPageComponent,
        resolve: {entity: PageResolver, settings: SettingsResolver},
        data: {ogMetaType: 'details'},
    },
    {
        path: 'faq',
        component: FaqPageComponent,
        resolve: {entity: PageResolver},
        data: {
            noColoredBanner: true,
            ogMetaType: 'faq',
        },
    },
    {
        path: 'privacy-policy',
        component: PrivacyPolicyPageComponent,
        resolve: {entity: PageResolver},
        data: {
            noColoredBanner: true,
            ogMetaType: 'privacy-policy',
        },
    },
    {
        path: 'tracks',
        component: TracksPageComponent,
        resolve: {entity: PageResolver},
        data: {ogMetaType: 'tracks'},
    },
    {
        path: 'project-ideas',
        component: ProjectIdeasPageComponent,
        resolve: {entity: PageResolver},
        data: {
            ogMetaType: 'project-ideas',
        },
    },
    {
        path: 'unihack-2019',
        component: AboutFirstEditionComponent,
        data: {
            ogMetaType: 'unihack-2019',
        },
    },
    {
        path: 'projects',
        component: ProjectsPageComponent,
        resolve: {entity: PageResolver, projects: ProjectsResolver},
        data: {
            noColoredBanner: true,
            ogMetaType: 'projects',
        },
    },
    {
        path: 'events',
        component: EventsPageComponent,
        resolve: {entity: PageResolver, events: EventsResolver},
        data: {
            ogMetaType: 'speakers',
        },
    },
    {
        path: 'people',
        component: PeoplePageComponent,
        resolve: {entity: PageResolver, people: PeopleResolver},
        data: {
            ogMetaType: 'people',
        },
    },
    {
        path: 'schedule',
        component: ScheduleComponent,
        data: {
            noColoredBanner: true,
            ogMetaType: 'schedule',
        },
    },
    {
        path: 't/:code',
        component: TrackingLandingPageComponent,
    },
    {
        path: 't',
        component: TrackingLandingPageComponent,
    },
    {
        path: '**',
        redirectTo: '/',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {initialNavigation: 'enabledBlocking'}),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
