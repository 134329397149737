import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { PersonModel } from '../../models/person.model';

@Component({
  selector: 'app-person-details-dialog',
  templateUrl: './person-details-dialog.component.html',
  styleUrls: ['./person-details-dialog.component.scss'],
})
export class PersonDetailsDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: PersonModel) { }

  ngOnInit() {
  }

}
