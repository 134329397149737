import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-civic-tracks',
    templateUrl: './civic-tracks.component.html',
    styleUrls: ['./civic-tracks.component.scss'],
})
export class CivicTracksComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
