<div class="attendance-section col">
    <div class="background"></div>
    <div class="container">
        <div class="row offset-1">
            <div class="col-12">
                <h2 class="section-title">Who can attend?</h2>
                <ul class="big-circle">
                    <li data-tool-tip="High School and University students">Students from all over the world</li>
                    <li>Coding newbies or programming experts</li>
                    <li>Challenge seekers</li>
                    <li>Someone eager to be better</li>
                    <li>Looking for the perfect mentor</li>
                </ul>
            </div>
        </div>
    </div>
</div>
