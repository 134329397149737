<div class="banner inner-shadow-top" id="letop" >
    <div class="container component vertical-align full-height-vh banner-background-container justify-content-center align-items-center"
         [ngStyle]="{'padding-top': heightService.getHeightPx}">
        <div class="banner-background"></div>
        <div class="row">
            <div class="col-12 titles mt-5">
                UniHack 2019
            </div>

            <div class="col-12 first-edition-content">

                <p>
                    The initiative of organising an <span class="font-weight-bold">international hackathon for
                    students</span> came from the predilection for coding and solution finding that
                    <a class="font-weight-bold" href="https://ligaac.ro" target="_blank">Liga AC</a>,
                    <a class="font-weight-bold" href="https://banatit.ro" target="_blank">Banat IT</a>
                    and their volunteers genuinely share. We wanted to help students improve their programming skills
                    and, at the same time, raise awareness about the problems we are dealing with in our society, so we
                    fulfilled these goals through <span class="font-weight-bold">UniHack</span>.
                </p>

                <p>
                    <span class="font-weight-bold">The first edition</span> of UniHack took place between 17-19 May 2019
                    in Timișoara, gathering over 200 students from all over the country who focused their IT passion
                    into developing remarkable hardware & software projects.
                </p>

                <p>
                    The participants started with a pitching session and, after forming the perfect team, they had 48
                    hours to implement their ideas. They were given the opportunity to learn new technologies through
                    Git & Docker beginner-level workshops and got help from mentors whenever they got stuck. The chill
                    zone was also there for them when they needed a break.
                </p>

                <p>
                    Their projects were meant to solve civic problems or improve everyday life through technology, and
                    the participants came up with some amazing ideas. Who wouldn’t want to control their dashboard
                    through hand motions or pay for all kinds of public transport using a universal ticketing system?
                </p>

                <p>
                    The best project was <span class="font-weight-bold">Parlamentarul meu</span> - an online platform
                    which connects citizens with elected politicians and shares information about their activity and
                    achievements. It impressed the jury & the public, winning the grand prize, goodies worth over
                    1000 €.
                </p>
            </div>
        </div>

        <a target="_blank" href="https://2019.unihack.eu" class="mt-5 website-button">
            <button class="btn btn-primary orange-button">Go to website</button>
        </a>

    </div>
</div>
