import { Component, OnInit } from '@angular/core';
import { GlobalSettings } from '../../../models/settings.model';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-event-details-page',
    templateUrl: './event-details-page.component.html',
    styleUrls: ['./event-details-page.component.scss'],
})
export class EventDetailsPageComponent implements OnInit {

    public settings: GlobalSettings;

    constructor(private actr: ActivatedRoute) {
        this.settings = this.actr.snapshot.data.settings;
    }

    ngOnInit() {
    }

}
