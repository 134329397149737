<div class="container-fluid">
    <div class="wheel-container">
        <!--<button (click)="spin()">-->
        <div class="indicator"><img src="/assets/wheel/wheel-indicator.png" /></div>
        <div class="wheel" id="wheel">
            <div class="left option">
                <img src="/assets/wheel/logo_unihack.png" />
                <div class="one option"><img src="/assets/wheel/pix_unihack.png" /></div>
                <div class="two option"><img src="/assets/wheel/minge_unihack.png" /></div>
            </div>
            <div class="right option">
                <img src="/assets/wheel/pix_minge_unihack.png" />
                <div class="one option"><img src="/assets/wheel/minge_sticker_unihack.png" /></div>
                <div class="two option"><img src="/assets/wheel/pix_sticker_unihack.png" /></div>
            </div>
            <button class="center" (click)="spin()"><img src="/assets/wheel/spin-icon.png" /></button>
        </div>
        <div id="wheel-overlay">
            <h1></h1>
            <img src="" alt=""/>
            <button type="button" (click)="hideOverlay()">Ok</button>
        </div>

        <!--        <button id="wheel-menu-button" type="button" (click)="showMenu()">
                    <img src="https://img.icons8.com/material-rounded/24/menu&#45;&#45;v1.png" alt="" />
                </button>
                <div id="wheel-menu">
                    <div *ngFor="let prize of prizes">
                        <input type="checkbox" id="{{'wheel-option-' + prize.id}}" checked="{{prize.active}}"/>
                        <label for="{{'wheel-option-' + prize.id}}">{{prize.name}}</label>
                    </div>
                    <button type="button" (click)="hideMenu()">Ok</button>
                </div>-->
        <!--</button>-->
    </div>
</div>

