<div class="banner paddingp"></div>
<div class="banner " id="feedback">
    <div class="container banner-background-container">
        <div class="banner-background"></div>
            <div class="banner-background"></div>
            <div>
                <form [formGroup]="trackForm" class="d-flex flex-column">
                    <mat-form-field class="mt-2">
                        <mat-label>Track</mat-label>
                        <mat-select formControlName="feedbackType" placeholder="Choose the track you want to write about">
                            <mat-option *ngFor="let Track of feedbackTypes" [value]="Track.value">
                                {{ Track.viewValue }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="trackForm.controls.feedbackType.hasError('required')">
                            This field is required
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Leave a comment</mat-label>
                        <textarea matInput [formControl]="trackForm.controls.message"
                                  placeholder="Write what you think about the selected track"></textarea>
                    </mat-form-field>
                </form>
                <div class="d-flex justify-content-end">
                    <button class="orange-button btn" style="position: relative" (click)="submit()">Send your message</button>
                </div>
            </div>
            <div class="centered">
                <img src="assets/images/cropped-IpB_sigla_text-nb.png" style="position: relative; max-width: 40%">
            </div>
            <div class="text-color text-center pt-3 pb-3" style="position: relative">
                We as programmers like to keep our code clean and efficient, so let's try to have the same perspective on the world we live.
                As one of the main goals of UniHack, civic engagement is crucial to the development of our personal growth and indirectly, our society.
                By completing this form, you'll help us make UniHack the best version it can be.
            </div>
    </div>
</div>
