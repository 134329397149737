import { Component, OnInit } from '@angular/core';
import { HeaderMenuHeightService } from '../../../../services/header-menu-height.service';


@Component({
  selector: 'app-tracks-banner',
  templateUrl: './tracks-banner.component.html',
  styleUrls: ['./tracks-banner.component.scss'],
})
export class TracksBannerComponent implements OnInit {

  constructor(public heightService: HeaderMenuHeightService) { }

  ngOnInit() {
  }

}
