import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { GlobalSettings } from '../models/settings.model';

@Injectable()
export class SettingsResolver implements Resolve<any> {

    constructor(protected apiService: ApiService) {
    }

    async resolve(): Promise<GlobalSettings> {
        return this.apiService.getSettings().toPromise();
    }
}
