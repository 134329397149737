import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SnackMessageService } from './services/snack-message.service';

const matImports = [
  MatSnackBarModule,
];

@NgModule({
  imports: [
    CommonModule,
    ...matImports,
  ],
})
export class SharedModule {
  public static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        SnackMessageService,
      ],
    };
  }
}
