import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { HeaderMenuHeightService } from '../../services/header-menu-height.service';
import { DialogService } from '../../services/dialog.service';

@Component({
    selector: 'app-header-menu',
    templateUrl: './header-menu.component.html',
    styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent implements OnInit, AfterViewInit {

    constructor(@Inject(PLATFORM_ID) private platformId: Object,
                private router: Router,
                public headerService: HeaderMenuHeightService,
                public dialogService: DialogService) {
        this.populateMenu();
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.isCollapsed = true;
            }
            if (event instanceof RoutesRecognized && event.state.root.firstChild.data) {
                this.noColoredBanner = event.state.root.firstChild.data.noColoredBanner === true;
            }
            if (event instanceof NavigationEnd && (event.url === '/projects' || event.url === '/feedback')) {
                this.noColoredBanner = true;
            }
        });
        // console.log(this.body);
    }

    public menuItems = [];
    public isTop = true;
    public isOnBanner = true;
    public isCollapsed = true;
    public noColoredBanner = false;

    // Snippet so the page no longer scrolls when menu open.
    // If there are many items in the menu, it will now scroll.

    ngOnInit() {
        if (isPlatformBrowser(this.platformId)) {
            window.addEventListener('scroll', (e) => {
                if (!this.dialogService.isDialogOpen) {
                    this.updatePositionVariables();
                    this.updateHeight();
                }
            });
            window.addEventListener('resize', (e) => {
                this.onWindowResize();
            });
        }
    }

    ngAfterViewInit(): void {
        if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
                this.updatePositionVariables();
                this.updateHeight();
            });
        }
    }

    private updatePositionVariables() {
        this.isOnBanner = window.pageYOffset <= window.innerHeight - 114;
        this.isTop = window.pageYOffset <= 64;
    }

    private updateHeight() {
        let h = this.headerService.defaultHeaderHeight;
        if (window.innerWidth >= 768) {
            this.isCollapsed = true;
            h = 66 + (this.isTop ? 48 : 0);
        }
        this.headerService.setHeight(h);
    }

    toggleMenuOpen() {
        this.isCollapsed = !this.isCollapsed;
        if (this.isCollapsed === false) {
            this.toggleBodyClass('stopScroll', true);
        } else {
            this.toggleBodyClass('stopScroll', false);
        }
    }

    onWindowResize() {
        // console.log('onWindowResize');
        if (window.innerWidth >= 768 && this.isCollapsed === false) {
            this.toggleMenuOpen();
        }
    }

    linkClicked() {
        this.toggleBodyClass('stopScroll', false);
        this.isCollapsed = true;
    }

    private toggleBodyClass(className: string, add: boolean): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }
        const body = document.getElementsByTagName('body')[0];
        if (add) {
            body.classList.add(className);
        } else {
            body.classList.remove(className);
        }
    }


    private populateMenu() {
            this.menuItems = [
                {
                    title: 'About',
                    routerLink: '/about',
                    href: '#dbtop',
                    activated: 'active',
                },
                {
                    title: 'Tracks',
                    routerLink: '/tracks',
                    href: '#trtop',
                    activated: 'active',
                },
/*                {
                    title: 'Events',
                    routerLink: '/events',
                    href: '#events',
                    activated: 'active',
                },*/
                {
                    title: 'People',
                    routerLink: '/people',
                    href: '#people',
                    activated: 'active',
                },
                {
                    title: 'FAQ',
                    routerLink: '/faq',
                    href: '#faq',
                    activated: 'active',
                },

                // {
                //     title: 'Home',
                //     routerLink: '/',
                //     href: '#top',
                //     activated: 'active',
                // },
                // {
                //     title: 'About Us',
                //     routerLink: '/about',
                //     href: '#abtop',
                //     activated: 'active',
                // },
                // {
                //     title: 'Previous Edition',
                //     routerLink: '/unihack-2019',
                //     href: '#letop',
                //     activated: 'active',
                // },

                // {
                //     title: 'Sponsors',
                //     routerLink: '/home',
                //     href: '#partners',
                //     activated: 'inactive',
                // },
                /* {
                     title: 'Project Ideas',
                     routerLink: '/project-ideas',
                     href: '#pitop',
                     activated: 'active',
                 },*/
                // {
                //     title: 'Contact',
                //     href: '#footer',
                //     activated: 'inactive',
                //     isOnlyScroll: true,
                // },
            ];
        }
}
