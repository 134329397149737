import { Component, Input, OnInit } from '@angular/core';
import { HeaderMenuHeightService } from '../../../../services/header-menu-height.service';
import { GlobalSettings } from '../../../../models/settings.model';
import { MatDialog } from '@angular/material/dialog';
import { PreregisterPopupComponent } from '../preregister-popup/preregister-popup.component';

declare let fbq: Function;

export interface DialogData {
    email: string;
    needVisa: boolean;
}
@Component({
    selector: 'app-banner',
    templateUrl: './home-page-banner.component.html',
    styleUrls: ['./home-page-banner.component.scss'],
})
export class HomePageBannerComponent implements OnInit {
    @Input()
    public settings: GlobalSettings;
    email: string;
    needVisa: boolean;
    constructor(public heightService: HeaderMenuHeightService,
                public dialog: MatDialog) {
    }
    sendFbEvent = () => fbq('track', 'RegistrationClick');
    ngOnInit() {
    }
    openDialog = (): void => {
       const dialogRef = this.dialog.open(PreregisterPopupComponent, {
            height: 'flex',
            data: {email: this.email, needVisa: this.needVisa },
        });
       dialogRef.afterClosed().subscribe(result => {
            this.email = result;
            this.needVisa = result;
        });
    }
}

