<div [ngClass]="{'is-on-banner': isOnBanner && !noColoredBanner, 'background-blue': !isTop || noColoredBanner}"
     class="fixed-top background-transition">
    <div class="container">
        <nav class="navbar navbar-expand-md justify-content-between col">
            <!-- Logo -->
            <a class="navbar-brand"
               (click)="toggleMenuOpen(); linkClicked();"
               href="#top"
               [routerLink]="'/'"
               pageScroll
               [pageScrollDuration]="500"
               [pageScrollAdjustHash]="true"
            >
                <img class="header-menu-logo" src="/assets/images/logos/unihack-2.svg"/>
            </a>
            <a class="navbar-brand"
               herf="https://centruldeproiecte.ro/"
               target="_blank">
                <img class="cdp-logo" src="/assets/images/logos/Logo_Funded_SVG.svg"
                     alt="Funded by the Center of Projects">
            </a>
            <button class="navbar-toggler"
                    type="button"
                    data-toggle="hidden"
                    data-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-label="Toggle navigation"
                    [attr.aria-expanded]="!isCollapsed"
                    (click)="toggleMenuOpen()">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="hidden navbar-collapse justify-content-end"
                 [ngClass]="{'overlay': !isCollapsed}">
                <!-- Links -->
                <div>
                    <div class="d-md-none"
                         [ngStyle]="{'padding-top':headerService.defaultHeaderHeight + 'px'}"
                    ></div>
                    <ul class="navbar-nav col">
                        <li *ngFor="let item of menuItems"
                            class="nav-item ml-5 ml-sm-3 ml-lg-4"
                            [routerLinkActive]="[item.activated]"
                            [routerLinkActiveOptions]="{exact: true}">
                            <a class="nav-link"
                               *ngIf="item.routerLink || item.isOnlyScroll"
                               [href]="item.href"
                               (click)="linkClicked()"
                               [routerLink]="item.routerLink"
                               pageScroll
                               [pageScrollDuration]="500"
                               [pageScrollAdjustHash]="true">{{item.title}}</a>
                            <a class="nav-link"
                               *ngIf="!item.routerLink && !item.isOnlyScroll"
                               [href]="item.href"
                               (click)="linkClicked()">{{item.title}}</a>

                        </li>
                    </ul>
                </div>
                <div class=" d-none d-md-block"></div>
                <div class="d-flex d-md-none nav-footer">
                    <div class="row pb-3">
                        <div class="col-12">
                            <a href="https://fb.com/unihackeu" target="_blank"><img
                                    src="../../../assets/images/logos/fb-logo.svg"/></a>
                            <a href="https://www.instagram.com/unihack_ro/" target="_blank"><img src="../../../assets/images/logos/insta-logo.svg"/></a>
                            <a href="https://www.linkedin.com/company/unihack-eu/" target="_blank"><img class="linkedin" src="../../../assets/images/logos/linkedin.png"/></a>
                        </div>
                        <div class="col-12 pt-3">
                            <span class="hashtag"><a href="https://fb.com/hashtag/unihack" target="_blank">#UniHack</a></span>
                        </div>
                    </div>
                </div>
            </div>
            <app-mlh-badge></app-mlh-badge>
        </nav>
    </div>
</div>
