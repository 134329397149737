<div id="partners"></div>
<div *ngIf="hasPartners" class="component inner-shadow-top">
    <div class="container">
        <ng-container *ngFor="let partnerType of partnerTypes">
            <div class="row" *ngIf="partnerType.display">
                <div class="col-12 mt-5 centered type-title">
                    {{partnerType.name}}
                </div>
                <ng-container [ngSwitch]="partnerType.display">
                    <ng-container *ngSwitchCase="'tiers'">
                        <ng-container *ngFor="let tier of partnerType.tiers">
                            <ng-template [ngTemplateOutlet]="partnersList"
                                         *ngIf="tier.partners.length"
                                         [ngTemplateOutletContext]="{partners: tier.partners, title: tier.name, logoSize: tier.logoSize}"></ng-template>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'partners'">
                        <ng-template [ngTemplateOutlet]="partnersList"
                                     [ngTemplateOutletContext]="{partners: partnerType.partners, logoSize: partnerType.logoSize}"></ng-template>
                    </ng-container>
                </ng-container>
            </div>
        </ng-container>

    </div>

    <ng-template #partnersList let-partners='partners' let-title="title" let-logoSize="logoSize">
        <div *ngIf="title" [className]="'col-12 mt-4 centered title-' + logoSize">
            {{title}}
        </div>
        <div class="col-12 centered">
            <div *ngFor="let partner of partners" [className]="'size-' + logoSize">
                <a [href]="partner.url" target="_blank"><img [src]="partner.logo.link" class="logo"/></a>
            </div>
        </div>
    </ng-template>
</div>
