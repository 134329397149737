<div class="banner inner-shadow-top" id="top">
    <div class="container full-height-vh vertical-align banner-background-container"
         [ngStyle]="{'padding-top': heightService.getHeightPx}">
        <div class="banner-background"></div>
        <div class="row"></div>
        <div class="row"></div>

        <!-- Info -->
        <div class="row justify-content-around">

            <div class="col-12 col-md-6 col-lg-5 col-xl-4">

                <!-- Details -->
                <div class="date text-center text-md-left mb-0">
                    {{ settings.homepageDate }}
                </div>
                <div class="location text-center text-md-left">
                    <div>
                        <a pageScroll
                           [pageScrollOffset]="100"
                           [pageScrollAdjustHash]="true"
                           [pageScrollDuration]="500"
                           href="#map">
                           <!--<span>Soon</span>-->
                        </a>
                    </div>
                    <div>
                        <img src="assets/images/location-pin.svg" class="location-pin"/>
                        {{settings.homepageLocation}}
                    </div>
                </div>
                <div class="text-color text-center text-md-left">
                    An international hackathon for university and high school students, with a focus on solving civic
                    issues.
                </div>
            </div>

            <!-- Logo -->
            <div class="col col-md-6 col-lg-5 col-xl-4 d-none d-md-flex flex-column justify-content-center banner-logo-wrapper">
                <img alt="logo" src="/assets/images/logos/unihack-2.svg"/>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="text-center pt-4" *ngIf="settings.homepageShowSchedule">
                    <a href="/schedule">
                        <button class="btn btn-primary orange-button register-button">Schedule</button>
                    </a>
                </div>
                <div class="text-center pt-4" *ngIf="settings.homepageRedirectionPortal">
                    <a href="/root">
                        <button class="btn btn-primary orange-button register-button">Login</button>
                    </a>
                </div>
                <div class="text-center pt-4" *ngIf="settings.homepageShowRegister">
                    <a href="/root">
                        <button class="btn btn-primary orange-button register-button" (click)="sendFbEvent()">Register now!</button>
                    </a>
                </div>
                <div class="text-center pt-4" *ngIf="settings.homepageShowPreregister">
                    <a>
                        <button class="btn btn-primary orange-button register-button" (click)="openDialog()">Preregister now!</button>
                    </a>
                </div>
                <div class="text-center pt-4" *ngIf="settings.homepageShowFeedback">
                    <a href="/feedback">
                        <button class="btn btn-primary orange-button register-button">Submit your idea!</button>
                    </a>
                </div>
                <div class="text-center pt-4" *ngIf="settings.homepageShowPastProjects">
                    <a href="/projects">
                        <button class="btn btn-primary orange-button register-button">See the projects</button>
                    </a>
                </div>
            </div>
        </div>
        <!-- Arrow -->
        <div class="row">
            <div class="col-12 text-center">
                <div class="arrow">
                    <a
                            pageScroll
                            [pageScrollOffset]="66"
                            [pageScrollAdjustHash]="true"
                            [pageScrollDuration]="500"
                            href="#details">
                        <div class="align-self-center text-find-out">Find out more!</div>
                        <img src="/assets/images/arrow.svg"/>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
