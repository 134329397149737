import { Component, OnInit } from '@angular/core';
import { HeaderMenuHeightService } from '../../../../services/header-menu-height.service';

@Component({
  selector: 'app-project-ideas-banner',
  templateUrl: './project-ideas-banner.component.html',
  styleUrls: ['./project-ideas-banner.component.scss'],
})
export class ProjectIdeasBannerComponent implements OnInit {

  constructor(public heightService: HeaderMenuHeightService) {
  }

  ngOnInit() {
  }

}
