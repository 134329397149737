import { Component } from '@angular/core';
import { EventModel } from '../../models/event.model';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-events-page',
    templateUrl: './events-page.component.html',
    styleUrls: ['./events-page.component.scss'],
})
export class EventsPageComponent {
    public talks: EventModel[] = [];
    public workshops: EventModel[] = [];
    public conference: EventModel[] = [];

    constructor(private actr: ActivatedRoute) {
        const events = this.actr.snapshot.data.events;
        events.forEach((e) => {
            switch (e.eventType) {
                case 'Workshop':
                    this.workshops.push(e);
                    break;
                case 'Talk':
                    this.talks.push(e);
                    break;
                case 'SpecialEvent':
                    this.conference.push(e);
                    break;
            }
        });
    }

    public dateExtractor(value: Date) {
        const aux = value.toString();
        return aux.substring(8, 10) + '-' + aux.substring(5, 7) + '-' + aux.substring(0, 4);

    }

    public hourExtractor(value: Date) {
        const aux = value.toString();
        const hour = aux.substring(aux.indexOf('T') + 1);
        return hour.substring(0, 5);

        // return value.toString().substring(value.toString().indexOf('T') + 1, value.toString().indexOf('T') + 6); bafta fra

    }

}
