<div class="banner inner-shadow-top" id="trtop">
    <div class="container full-height-vh vertical-align banner-background-container"
         [ngStyle]="{'padding-top': heightService.getHeightPx}">
        <div class="banner-background"></div>
        <div></div>
        <div class="row justify-content">
            <div class="col-8 col-md-6 offset-1">
                <div class="titles d-none d-md-block ">Prizes</div>
                <div class="titles d-block d-md-none">Prizes</div>
                <div class="description text-md-left">
                    <div class="d-block">
                        <p>
                            Whether you're developing a hardware or a software based project, your
                            team might be eligible to win the sponsor
                            award for one or more tracks.
                        </p>
                        <p>
                            Check out this edition's prizes and decide where your project fits in!
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-center">
                <div class="arrow">
                    <a
                            [routerLink]="'/tracks'"
                            pageScroll
                            [pageScrollOffset]="66"
                            [pageScrollAdjustHash]="true"
                            [pageScrollDuration]="500"
                            href="#tracks">
                        <div class="text-find-out mt-4">See the prizes!</div>
                        <img src="/assets/images/arrow.svg"/>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
