<div class="banner inner-shadow-top" id="trtop">
    <div class="container full-height-vh vertical-align banner-background-container"
         [ngStyle]="{'padding-top': heightService.getHeightPx}">
        <div class="banner-background"></div>
        <div></div>
        <div class="row justify-content">
            <div class="col-8 col-md-6 offset-1">
                <div class="titles">Mentors & Jury</div>
                <div class="description text-md-left">
                    <div class="d-block">
                        <p>
                            Wondering who will be with you during UniHack?
                        </p>
                        <p>
                            Take a look at the list of amazing mentors who will help you every step of the way, and the
                            judges who will evaluate your projects!
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-center">
                <div class="arrow">
                    <a
                            [routerLink]="'/people'"
                            pageScroll
                            [pageScrollOffset]="66"
                            [pageScrollAdjustHash]="true"
                            [pageScrollDuration]="500"
                            href="#people">
                        <div class="text-find-out mt-4">Check it out</div>
                        <img src="/assets/images/arrow.svg"/>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
