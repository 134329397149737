<div id="pptop">
    <div class="container component">
        <div class="row">
            <div class="col-12 page-title">
                Privacy Policy
            </div>

            <div class="col-12 privacy-content">
                <p dir="ltr">Liga Studenților din Facultatea de Automatică și Calculatoare (Liga AC – The students’ organization from the Faculty of Automation and Computing), residing in Timișoara, Timiș county, Romania, blvd. Vasile Pârvan, no. 2, room A021, TIN 7267553, further called the “Controller”, represented by Alexandru-Petrică Brăescu, the person responsible with implementing of the measures regarding personal data protection; he can be reached on the phone at 0040 787 707 911, or via email at <a href="mailto:contact@ligaac.ro">contact@ligaac.ro</a>, according to article 13 of the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation – “GDPR”), in effect starting with 25 May 2018, we notify you of the following:</p>
                <p>&nbsp;</p>

                <p dir="ltr">Liga AC, as a personal data controller, is constantly concerned with providing a high level of personal protection regarding the personal data processing that we perform according to the law and regulations in effect. We implement technical and organizational measures to protect all the operations that directly or indirectly concern personal data and to prevent unauthorized or illegal operations, as well as accidental or illegal data loss or damage. In this regard, the controller is subject to the control of The National Supervisory Authority for Personal Data Processing (ANSPDCP).</p>

                <p dir="ltr">According to article 6 of the General Data Protection Regulation, data processing shall be lawful only if and to the extent that at least one of the following applies:</p>

                <ol>
                    <li dir="ltr">
                        <p dir="ltr">the data subject has given consent to the processing of his or her personal data for one or more specific purposes;</p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">processing is necessary for the performance of a contract to which the data subject is party or in order to take steps at the request of the data subject prior to entering into a contract;</p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">processing is necessary for compliance with a legal obligation to which the controller is subject;</p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">processing is necessary in order to protect the vital interests of the data subject or of another natural person;</p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller;</p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">processing is necessary for the purposes of the legitimate interests pursued by the controller or by a third party, except where such interests are overridden by the interests or fundamental rights and freedoms of the data subject which require protection of personal data, in particular where the data subject is a child.</p>
                    </li>
                </ol>

                <p>&nbsp;</p>

                <p dir="ltr">The PURPOSE of personal data processing by Liga AC is to fulfil our specific activity and the subject of the contract with the data subject, as well as the fulfilment of legal obligations concerning financial, commercial and international law matters and the compliance with all the legal provisions regarding our activity.</p>

                <p dir="ltr">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>

                <p dir="ltr">The LEGAL GROUND for personal data processing by Liga AC is, mainly, article 6, paragraph (1), letter b) from GDPR - processing is necessary for the performance of a contract, as well as, article 6, paragraph (1), letter c) from GDPR &ndash; processing is necessary for compliance with a legal obligation &ndash; the legal obligations that apply to the controller are regulated by labor law or any other legislative or administrative document or decision relating to data collection and processing.</p>

                <p>&nbsp;</p>

                <p dir="ltr">In order to fulfil our tasks and the obligations imposed by the aforementioned legal documents, the controller processes the following PERSONAL DATA CATEGORIES:</p>

                <ul>
                    <li dir="ltr">
                        <p dir="ltr">First, middle and last names, home addresses, personal identification number and any data from identity documents for:</p>

                        <ul>
                            <li dir="ltr">
                                <p dir="ltr">Signing and executing a contract;</p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">Billing and carrying out payments;</p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">Fulfilling accounting and financial obligations;</p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">Drawing up the accounts required by law;</p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">Settling financing projects;</p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">Displaying results or rankings from competitions, projects or selection processes organized by Liga AC;</p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">Drawing up statistics following projects, activities or events in order to observe the impact of the project/activity/event; the statistics are not nominal.</p>
                            </li>
                        </ul>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">Bank account data for making payments;</p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">Phone numbers, email addresses, Facebook/Instagram/YouTube/Twitter or other social media accounts, websites for:</p>

                        <ul>
                            <li dir="ltr">
                                <p dir="ltr">Communication regarding the execution of a contract or results;</p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">Providing new pieces of information regarding projects or events organized by Liga AC;</p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">Contacting the data subject for their confirmation for participating in the events that we collected data for;</p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">Providing information about a project, such as scheduling data, organizers&rsquo; contacts, meeting locations etc.;</p>
                            </li>
                            <li dir="ltr">
                                <p dir="ltr">Efficiently communicating with or promoting partners.</p>
                            </li>
                        </ul>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">Personal data regarding clothing or footwear, culinary preferences, gender, sexual orientation, race, nationality, any other options required by signup forms are required and internally processed for having a richer experience in the projects, activities or events that participants, collaborators or partners take part.</p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">Images or videos taken during the event, used for promotional purpose only, on the controller's social media accounts.</p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">Any other &ldquo;personal data&rdquo;, meaning any information regarding an identified or identifiable natural person (&ldquo;data subject&rdquo;); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.</p>
                    </li>
                </ul>

                <p>&nbsp;</p>

                <p dir="ltr">According to the GDPR, exceptionally, the processing of the following SPECIAL PERSONAL DATA CATEGORIES requires a higher protection standard: racial or ethnic background, political opinions, religious orientation, philosophical convictions, syndicate memberships, genetic data, biometric data that can uniquely identify a person, data regarding health or the sexual life or orientation of a natural person.</p>

                <p dir="ltr">In order to process special personal data, besides identifying grounds for processing in accordance with article 6 of the GDPR, it is required that one of the conditions stated in article 9, paragraph (2) of the General Data Protection Regulation is satisfied. Those conditions are as follows:</p>

                <ul>
                    <li dir="ltr">
                        <p dir="ltr">The explicit consent of the data subject;</p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">The processing is necessary in order to fulfil some obligations and to exercise specific rights belonging to the controller or to the data subject.</p>
                    </li>
                </ul>

                <p>&nbsp;</p>

                <p dir="ltr">The RECIPIENTS of personal data are the controller&rsquo;s proxies, employees, the accounting and human resources departments, as well as clients, partners, beneficiaries, suppliers and state institutions.</p>

                <p dir="ltr">We do not intend to transfer data to other companies (marketing or advertising companies).</p>

                <p dir="ltr">We do not intend to transfer personal data to another country or international organization unless we are partners or collaborators and this fact is clearly stated in the data collection form.</p>

                <p dir="ltr">Information regarding personal data might be communicated, by request, to the following recipients or recipient categories: the National Authority for Community Programs in Education and Professional Training (ANPCDEFP), the Ministry of Finances, the Ministry of Youth and Sports, Timișoara City Hall, Timiș County Council, the Sports and Youth Department of Timiș County, the Ministry of Culture and National Identity, the Ministry of Education, universities in Timișoara, the Timiș County School Inspectorate and subordinate institutions, public authorities with attributions regarding defense, public order, supervising and controlling economic activity, national security and justice, institutions or authorities of public administration, foreign diplomatic missions recognized by Romania, public institutions or authorities in healthcare and social insurance, other public or private entities that can prove a legitimate interest, according to the law.</p>

                <p dir="ltr">Providing that other personal data is required, the petitioner must provide legitimate interest, according to the law.</p>

                <p dir="ltr">Personal data may be processed by the petitioners only for the purpose they were required for and provided for, except in situations stated by the law.</p>

                <p dir="ltr">If the petitioner intends to process the personal data acquired from the controller, they have the obligation to respect the regulations regarding personal data processing.</p>

                <p>&nbsp;</p>

                <p dir="ltr">The DURATION of personal data storage and processing is limited, for as long as the contract between the two parties is valid, in case of litigation &ndash; for the duration of the settlement of said litigation, as well as in accordance with the law and for as long as it is legally required that we keep work contracts, documents regarding payments or accounting or other legal documents that we must keep in accounting and society archives. Furthermore, personal data belonging to participants in project, activities or events organized by Liga AC is stored for up to ten years or until the data subject requests its erasure.</p>

                <p>&nbsp;</p>

                <p dir="ltr">As a data subject, the following RIGHTS apply to you:</p>

                <ul>
                    <li dir="ltr">
                        <p dir="ltr">The right to access &ndash; you have the right to obtain confirmation from us that we do or do not process personal data that is of interest to you, and to gain access to your own data and to information regarding the means of data collecting.</p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">The right to data portability &ndash; you have the right to receive personal data in a structured, commonly used format that can be automatically read and the right to have this data transferred to another controller should this be possible from a technical standpoint.</p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">The right to opposition &ndash; you have the right to oppose personal data processing when the processing is necessary to a task that serves public interest or a legitimate interest of the controller. When personal data is processed for direct marketing, you have the right to oppose processing at any moment.</p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">The right to rectification &ndash; you have the right to have your personal data rectified, without unjustified delays. The rectification will be further communicated to any recipient whom the data was previously sent to, unless this proves to be impossible or entails disproportionate effort.</p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">The right to data erasure (&ldquo;the right to be forgotten&rdquo;) &ndash; you have the right to request that your personal data is erased, without unjustified delays, provided one of the following reasons apply: the data is no longer necessary in order to fulfil the purpose it was collected or processed for, you withdraw consent and there is no longer legal ground for the processing, you oppose the processing and there are no other prevalent legitimate reasons, personal data must be erased in order to respect a legal obligation, the personal data was illegally processed, the personal data was collected in order to provide services of an informational society.</p>
                    </li>
                    <li dir="ltr">
                        <p dir="ltr">The right to restriction of processing &ndash; it can be exercised if the accuracy of the personal data is contested by the data subject for a period of time that allows us to check the correctness of the data; the processing is unlawful and the data subject opposes personal data erasure, requesting restriction instead; if the controller no longer needs the personal data for processing but the data subject requests the data for the establishment, exercise or defense of a legal claim; the data subject objected processing for the time period pending the verification whether the legitimate grounds of the controller override those of the data subject.</p>
                    </li>
                </ul>

                <p dir="ltr">All these rights may be exercised via a written, signed and dated request that is sent to our headquarters or via fax or email at the contact details previously mentioned and the controller will respond to the request without unjustified delays, in a month from the date of its receival, or in exceptional situations, if the data subject&rsquo;s request is complex, the controller will respond in a period of time of maximum three months from its receival and the data subject will be notified of this situation.</p>

                <p dir="ltr">We inform that we do not use personal data for automatic processing or for creating profiles. We do not make automatic decisions regarding your personal data. We use technological means to store personal data securely. We do not process data for purposes that are incompatible with the purpose the data was collected for.</p>

                <p dir="ltr">We respect and strictly ensure professional secrets. We do not publish personal data, unless it is in your interest or to fulfil legal obligations. Confidentiality represents not only a professional obligation for us, but also a fundamental value.</p>

                <p dir="ltr">We will take technical and procedural measures in order to protect and to ensure the confidentiality, integrity and accessibility of your personal data that is processed by us. We will prevent unauthorized access or usage and personal data security violations, according to the law.</p>

                <p dir="ltr">Furthermore, as a data subject, should you be unhappy with how we apply the regulations and respect the rights regarding personal data protection, you have the right to file a complaint with the National Authority for Community Programs in Education and Professional Training (Autoritatea Națională de Supraveghere a Prelucrării Datelor cu Caracter Personal - ANSPDCP) at their headquarters at blvd. General Gheorghe Magheru no. 28-30, sector 1, Bucharest, postal code 0103336, email: <a href="mailto:anspdcp@dataprotection.ro">anspdcp@dataprotection.ro</a> &nbsp;or to take legal action.</p>

                <p dir="ltr">We inform that an intervention over the personal data you provided us might prevent us from fulfilling the contract signed between you and the controller, the controller being released from any obligation or liability in such event.</p>

            </div>
        </div>
    </div>
</div>
