import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantsService } from './constants.service';
import { Feedback } from '../pages/track-form/track-form.component';
@Injectable({
  providedIn: 'root',
})
export class TrackformService {

  constructor(private http: HttpClient,
              private constants: ConstantsService) {
  }
  public AddTrackForm(info: Feedback): Promise<{ data: Feedback }> {
    const url = `${this.constants.api}/Feedback/add`;
    console.log(info);
    return this.http.post<{ data: Feedback }>(url, info).toPromise();
  }
}
