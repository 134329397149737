<div mat-dialog-content>
    <p>Enter your email</p>
    <form [formGroup]="preregisterForm" class="d-flex flex-column justify-content-around align-baseline">
        <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input type="email" matInput [formControl]="preregisterForm.controls.email" placeholder="Ex.unihacker@gmail.com">
            <mat-error *ngIf="preregisterForm.controls.email.hasError('required')">
                This field is required
            </mat-error>
            <mat-error *ngIf="preregisterForm.controls.email.hasError('email') && !preregisterForm.controls.email.hasError('required')">
                This is not a valid email
            </mat-error>
        </mat-form-field>
        <div class="d-flex flex-row justify-content-start align-items-center">
            <input type="checkbox" style="height: 15px" id="visa" [formControl]="preregisterForm.controls.needVisa">
            <label for="visa" class="m-1">I will need a visa to be able to travel to UniHack 2024 in Timisoara, Romania</label>
        </div>
        <div style="color: #939598">Not sure if you need a visa to enter Romania? <a href="https://eviza.mae.ro/" target="_blank">Click here</a> and "Get Informed". Choose Other (C/ZA) for a tourist visa.</div>
    </form>
    <button (click)="submit()" class="btn btn-primary orange-button register-button" [mat-dialog-close]="{}">Submit</button>
</div>



