import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SnackMessageService } from '../../services/snack-message.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TrackformService } from '../../services/trackform.service';
export interface Track {
    value: string;
    viewValue: string;
}
export interface Feedback {
    feedbackType: number;
    message: string;
}
@Component({
  selector: 'app-track-form',
  templateUrl: './track-form.component.html',
  styleUrls: ['./track-form.component.scss'],
})
export class TrackFormComponent implements OnInit {
    constructor(private snack: SnackMessageService,
                private router: Router,
                private trackFormService: TrackformService) {
    }
    feedbackTypes: Track[] = [
        {value: '0', viewValue: 'Education'},
        {value: '1', viewValue: 'Social Inclusion'},
        {value: '2', viewValue: 'Ecology'},
        {value: '3', viewValue: 'Public Administration'},
    ];
    public saving = false;
    selectedValue: string;
    public trackForm: FormGroup<{ feedbackType: FormControl<number>; message: FormControl<string> }>;

    public ngOnInit() {
        this.buildForm();
    }
    private buildForm(): void {
        this.trackForm = new FormGroup({
            feedbackType: new FormControl<number>(0, [Validators.required]),
            message: new FormControl<string>('', [Validators.required]),
        });
    }
    public async submit() {
        this.snack.clear();

        if (!this.trackForm.valid) {
            this.trackForm.markAllAsTouched();
            this.snack.display('Check the form before submitting.');
            return;
        }
        try {
            const feedback: Feedback = { feedbackType : this.trackForm.value.feedbackType, message : this.trackForm.value.message};
            console.log(this.trackForm.value.feedbackType);
            await this.trackFormService.AddTrackForm(feedback);
            this.snack.display('Your feedback has been accepted!');
            await this.router.navigate(['']);
        } catch (e) {
            console.log('da');
            this.snack.showError(e);
        }
        this.saving = false;
    }
}



