<div id="tracks"></div>
<div *ngIf="tracks && tracks.length" class="inner-shadow-top component">
    <div class="container">
        <div class="centered track mb-5 grand-prize">
            <div class="col-12 flex-column">
                <h2 class="title title-grand-prize centered">Grand Prize</h2>
                <h2 class="centered prizes mt-4">2000 EUR / team</h2>
            </div>
        </div>
        <div *ngFor="let track of tracks; index as i" class="row justify-content-around track mb-5">
            <div *ngIf="i>0" class="col-12 pb-5">
                <hr/>
            </div>
            <div class="col-12 col-md-6 mb-5 mb-md-0 d-flex align-items-center">
                <div class="track-info">
                    <div class="d-flex mb-2">
                        <img class="tracks-logo d-flex align-top" [src]="tracksLogo[i]" alt="">
                    <h2 class="title d-flex justify-content-md-start align-items-center pl-1"
                    [class.mb-0]="hasDescription(track)"
                    >{{track.name}}</h2>
                    </div>
                    <div [innerHTML]="track.shortDescription"></div>
                    <p *ngIf="hasJudgingCriteria(track)"><strong>Judging Criteria:</strong></p>
                    <div [innerHTML]="track.judgingCriteria"></div>
                    <p *ngIf="hasExamples(track)"><strong>Project examples:</strong></p>
                    <div [innerHTML]="track.examples"></div>
                </div>
            </div>
            <div class="col-12 col-md-4 company-info d-flex flex-column justify-content-center">
<!--                    <p class="img-label">Track judged by:</p>-->
                    <a [href]="track.company.url" class="text-center">
                        <h1 *ngIf="!track.company.logo">N0am</h1>
                        <img *ngIf="track.company.logo" [src]="track.company.logo.path"/>
                    </a>
                    <div *ngIf="hasPrizes(track)" class="prizes">
                        <p class="prizes-label" *ngIf="i==0">Prize offered by Haufe Group:</p>
                        <p class="prizes-label" *ngIf="i==1">Prize offered by Nokia:</p>
                        <p class="prizes-label" *ngIf="i==2">Prize offered by The Access Group:</p>
                        <p class="prizes-label" *ngIf="i==3">Prize offered by Primăria Municipiului Timișoara:</p>
                        <p class="prizes-label" *ngIf="i==4">Prize offered by Incluziune pe Bune:</p>
                        <p class="prizes-label" *ngIf="i==5">Prize offered by BCR:</p>
                        <div [innerHTML]="track.prizes" class="mt-5"></div>
                    </div>
            </div>
        </div>

            <!--    French prize-->
        <!--<div class="row justify-content-around track mb-5 french">
            <div class="col-12 pb-5">
                <hr/>
            </div><div class="col-12 col-md-6 justify-content-center d-flex justify-content-md-start">
            <img src="../../../../../assets/images/logos/embassy.svg"/>
            <img src="../../../../../assets/images/logos/institute.svg"/>
        </div>
            <div class="col-12 col-md-4 prizes justify-content-center align-items-center d-flex mt-2 mt-md-0">
                <div>500 EUR / team</div>
            </div>
        </div>-->

<!--              //Grand prize-->

    </div>
</div>
