<div class="banner inner-shadow-top" id="stop">
    <div [ngStyle]="{'padding-top': heightService.getHeight() + 40 + 'px'}"
         class="container banner-background-container full-height-vh vertical-align">
        <div class="banner-background"></div>
        <div *ngIf="schedule" class="row"></div>
        <div class="row justify-content-around">
            <div class="col-12 col-sm-10">
                <div class="titles">Schedule</div>
                <div class="subtitle">(times displayed are in Eastern European Time)</div>
            </div>
        </div>
        <ng-container *ngFor="let event of schedule">
            <div class="row mb-5 offset-sm-1">
                <div class="col-12 col-sm-2 entry-day ml-3 d-flex">{{event.date | date: "EEEE"}}</div>
                <div class="col-xs-12 col-lg-8">
                    <div *ngFor="let entry of event.entries" class="ml-3 mb-4 left-padding">
                        <div [class.happening]="isHappening(entry)" class="p-2 col-lg-10">
                            <div class="d-flex mb-2 time-wrapper">
                                <div class="mr-1">{{timeZoneOffset(entry.startTime)| date: "HH:mm"}}</div>
                                <div *ngIf="entry.displayEndTime"> - {{timeZoneOffset(entry.endTime) | date: "HH:mm"  }}</div>
                            </div>
                            <div class="d-flex flex-column justify-content-center">
                                <div class="entry-name">{{entry.title}}</div>
                                <div *ngIf="entry.hostedBy" class="entry-name mt-2">Hosted by <span
                                        class="hosted-by">{{entry.hostedBy}}</span></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>

