<h1 mat-dialog-title>{{data.firstName}} {{data.lastName}}</h1>
<mat-dialog-content>
    <div class="person-title mb-5">{{ data.function }}</div>
    <div [innerHTML]="data.description" class="mb-5"></div>
    <div *ngIf="data.linkedin || data.twitter || data.otherLink" class="links mb-1">
        <a *ngIf="data.linkedin" [href]="data.linkedin" target="_blank">
            <img src="../../../assets/images/icons/linkedin-logo.svg">
        </a>
        <a *ngIf="data.twitter" [href]="data.twitter" target="_blank">
            <img src="../../../assets/images/icons/twitter-logo.svg">
        </a>
        <a *ngIf="data.otherLink" [href]="data.otherLink" target="_blank">
            <img src="../../../assets/images/icons/web-icon.svg">
        </a>
    </div>
</mat-dialog-content>
