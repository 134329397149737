import { Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';
import { PersonModel } from '../models/person.model';

@Injectable()
export class PeopleResolver implements Resolve<any> {

    constructor(protected apiService: ApiService) {
    }

    async resolve(): Promise<PersonModel[]> {
        const people = await this.apiService.getPeople();
        const obj = people.reduce((acc, person) => {
            if (person.personType !== 'Judge' && person.personType !== 'Mentor' && person.personType !== 'MentorAndJudge') {
                return acc;
            }
            const key = `${person.firstName}-${person.lastName}`;
            if (!acc[key]) {
                acc[key] = person;
            }
            if (person.personType === 'Judge') {
                acc[key].isJudge = true;
            }
            if (person.personType === 'Mentor') {
                acc[key].isMentor = true;
            }
            if (person.personType === 'MentorAndJudge') {
                acc[key].isMentorAndJudge = true;
            }
            return acc;
        }, {});
        return Object.values(obj);
    }
}
