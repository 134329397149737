import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalSettings } from '../../../models/settings.model';

@Component({
    selector: 'app-landing-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
    public settings: GlobalSettings;

    constructor(private actr: ActivatedRoute) {
        this.settings = this.actr.snapshot.data.settings;
    }

    ngOnInit() {
    }

}
