<div class="banner " id="faq" #banner>
    <div class="container banner-background-container">
        <div class="banner-background"></div>
        <div class="content-wrapper">
            <div class="row">
                <div class="col">
                    <h4 class="titles padding" #titleElement>Frequently Asked Questions</h4>
                </div>
            </div>
            <div class="row flex-column flex-md-row">
                <div class="left-container d-none d-md-block">
                    <div class="categories-wrapper"
                         [ngClass]="{'faq-fixed-top': categoryWrapper.fixedTop, 'faq-fixed-bottom': categoryWrapper.fixedBottom}"
                         [ngStyle]="{top: headerMenuHeight + 15 + 'px'}">
                        <ng-container *ngFor="let faqCategory of faqCategories; let i = index">
                            <div [ngClass]="auto_select_category_on_scroll(i) ? {highlight: faqCategory.selected} : null"
                                 (click)="selectCategory(i)"
                                 *ngIf="faqCategory.name"
                                 class="category-name">
                                <div>
                                    {{faqCategory.name}}
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="left-container-mobile  d-block d-md-none"
                     [ngClass]="{'faq-fixed-top': categoryWrapper.fixedTop || categoryWrapper.fixedBottom}"
                     [ngStyle]="{top: headerMenuHeight + 'px'}">
                    <div class="categories-wrapper container">
                        <div>
                            <div class="category-name">
                                <select (change)="selectChanged()" [(ngModel)]="selectedIndex">
                                    <ng-container *ngFor="let faqCategory of faqCategories; let i = index">
                                        <option *ngIf="faqCategory.name" [value]="i">{{faqCategory.name}}</option>
                                    </ng-container>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-container">
                    <ng-container *ngFor="let faqCategory of faqCategories; let i = index">
                        <div class="category-name" #faqCategoryElements
                             [ngClass]="{highlight: faqCategory.selected}"
                             (click)="selectCategory(i)">{{faqCategory.name}}</div>
                        <div class="question" *ngFor="let faq of faqCategory.faqs">
                            <p class="question-title">{{faq.question}}</p>
                            <div class="question-body" [innerHTML]="faq.answer"></div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
