import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { ApiService } from './api.service';
import Timer = NodeJS.Timer;

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsService {

    private trackingCodeSet: boolean;

    constructor(@Inject(PLATFORM_ID) private platformId: Object,
                private router: Router,
                private apiService: ApiService) {
        this.trackingCodeSet = false;
        if (isPlatformBrowser(this.platformId)) {
            this.router.events.subscribe(event => {
                if (event instanceof NavigationEnd) {
                    this.pageViewEvent(event.urlAfterRedirects);
                }
            });
            this.apiService.getSettingOrThrow('gaTrackingCode').then(setting => {
                if (setting && (<any>window).ga) {
                    (<any>window).ga('create', setting, 'auto');
                    this.trackingCodeSet = true;
                    this.processWaitingQueue();
                } else {
                    this.disabled = true;
                    this.waitingQueue = [];
                }
            });
        }
    }

    private disabled: boolean;

    private waitingQueue: string[] = [];

    private timeoutId: Timer;

    public pageViewEvent(url: string, force?: boolean): void {
        if (this.disabled) {
            return;
        }
        if (force) {
            this.secondStep(url);
        } else {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = setTimeout(() => {
                this.timeoutId = null;
                this.secondStep(url);
            }, 250);
        }
    }

    private secondStep(url: string) {
        if (!this.trackingCodeSet) {
            this.waitingQueue.push(url);
            return;
        }
        this.sendPageViewEvent(url);
    }

    private processWaitingQueue(): void {
        if (this.waitingQueue.length === 0) {
            return;
        }
        const url = this.waitingQueue.splice(0, 1)[0];
        this.secondStep(url);
        setTimeout(() => {
            this.processWaitingQueue();
        }, 500);
    }

    private sendPageViewEvent(url: string): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }
        (<any>window).ga('set', 'page', url);
        (<any>window).ga('send', 'pageview');
    }
}

