<div id="people"></div>
<div *ngIf="people && people.length" class="inner-shadow-top component">
    <div class="container people-grid">
        <div *ngFor="let person of people; index as i" class="person">
            <div>
                <app-person-card [enableDialog]="false" [lightbg]="true" [person]="person"></app-person-card>
                <div [innerHTML]="person.description" class="description"></div>
                <div *ngIf="person.linkedin || person.twitter || person.otherLink" class="links">
                    <a *ngIf="person.linkedin" [href]="person.linkedin" target="_blank">
                        <img src="../../../assets/images/icons/linkedin-logo.svg">
                    </a>
                    <a *ngIf="person.twitter" [href]="person.twitter" target="_blank">
                        <img src="../../../assets/images/icons/twitter-logo.svg">
                    </a>
                    <a *ngIf="person.otherLink" [href]="person.otherLink" target="_blank">
                        <img src="../../../assets/images/icons/web-icon.svg">
                    </a>
                </div>
            </div>
            <div class="pills-wrapper">
                <div *ngIf="person.isMentor" class="pill mentor">Mentor</div>
                <div *ngIf="person.isJudge" class="pill judge">Judge</div>
                <div *ngIf="person.isMentorAndJudge" class="row">
                    <div class="pill mentor mr-1">Mentor</div>
                    <div class="pill judge">Judge</div>
                </div>
            </div>
        </div>
    </div>
</div>
