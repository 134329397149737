import { Component, OnInit } from '@angular/core';
import { HeaderMenuHeightService } from '../../../services/header-menu-height.service';

@Component({
    selector: 'app-about-first-edition',
    templateUrl: './about-first-edition.component.html',
    styleUrls: ['./about-first-edition.component.scss'],
})
export class AboutFirstEditionComponent implements OnInit {

    constructor(public heightService: HeaderMenuHeightService) {
    }

    ngOnInit() {
    }

}
