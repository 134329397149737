import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-reasons-to-come',
    templateUrl: './reasons-to-come.component.html',
    styleUrls: ['./reasons-to-come.component.scss'],
})
export class ReasonsToComeComponent implements OnInit {
    public reasons = [];

    constructor() {
        this.initializeReasons();
    }

    ngOnInit() {
    }

    private initializeReasons() {
        this.reasons = [
            // {
            //     imageSrc: 'assets/images/icons/accomodation.svg',
            //     text: 'Free accommodation',
            // },
            {
                imageSrc: 'assets/images/icons/light-bulb.svg',
                text: 'Develop new projects',
            },
            {
                imageSrc: 'assets/images/icons/mentor.svg',
                text: 'Talks and workshops',
            },
            {
                imageSrc: 'assets/images/icons/no-dollars-accepted.svg',
                text: 'No participation fee',
            },
            {
                imageSrc: 'assets/images/icons/hat-and-magic-wand.svg',
                text: 'Learn new tricks',
            },
            {
                imageSrc: 'assets/images/icons/laugh.svg',
                text: 'Code & have fun with your team',
            },
        ];
    }
}
