import { Component, OnInit } from '@angular/core';
import { HeaderMenuHeightService } from '../../../../services/header-menu-height.service';


@Component({
  selector: 'app-people-banner',
  templateUrl: './people-banner.component.html',
  styleUrls: ['./people-banner.component.scss'],
})
export class PeopleBannerComponent implements OnInit {

  constructor(public heightService: HeaderMenuHeightService) { }

  ngOnInit() {
  }

}
