import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-cookies-popup',
    templateUrl: './cookies-popup.component.html',
    styleUrls: ['./cookies-popup.component.scss'],
})
export class CookiesPopupComponent {

    public enabled: boolean;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        if (isPlatformBrowser(this.platformId)) {
            if (document.cookie.indexOf('accepted_cookie=true') === -1) {
                this.enabled = true;
            }
        }
    }

    public acceptedCookies() {
        if (isPlatformBrowser(this.platformId)) {
            document.cookie = 'accepted_cookie=true';
            this.enabled = false;
        }
    }
}
