<div *ngIf="enabled" class="cookieConsent container-fluid" id="cookiesConsent">
    <div class="row">
        <div class="col-12 col-sm-8 align-items-sm-end justify-content-sm-end d-sm-flex">
            <span>Our website uses cookies to improve your experience. We'll assume you're ok with this.</span>
        </div>
        <div class="col-12 col-sm-4 align-items-sm-start justify-content-sm-start d-sm-flex">
            <a (click)="acceptedCookies()" class="cookieConsentOK">I agree</a>
        </div>
    </div>
</div>
