import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../services/api.service';
import { PartnerTypeModel } from '../../../../models/partner-type.model';

@Component({
    selector: 'app-partners',
    templateUrl: './partners.component.html',
    styleUrls: ['./partners.component.scss'],
})
export class PartnersComponent implements OnInit {
    public partnerTypes: PartnerTypeModel[];

    constructor(private apiService: ApiService) {
    }

    async ngOnInit() {
        this.partnerTypes = await this.apiService.getPartners();
        for (const type of this.partnerTypes) {
            if (type) {
                if (type.partners && type.partners.length) {
                    if (type.tiers && type.tiers.length) {
                        type.display = 'tiers';
                    } else {
                        type.display = 'partners';
                    }
                }
            }
        }
    }

    public get hasPartners() {
        return this.partnerTypes && this.partnerTypes.length;
    }

}
