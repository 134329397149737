import { Component, Input } from '@angular/core';
import { PersonModel } from '../../models/person.model';
import { DialogService } from '../../services/dialog.service';


@Component({
    selector: 'app-person-card',
    templateUrl: './person-card.component.html',
    styleUrls: ['./person-card.component.scss'],
})
export class PersonCardComponent {
    @Input() person: PersonModel;
    @Input() lightbg = false;
    @Input() enableDialog = true;

    constructor(public dialogService: DialogService) {
    }

    public openDialog() {
        if (this.enableDialog) {
            this.dialogService.openPersonDetailsDialog(this.person);
        }
    }

    public get imageUrl() {
        return encodeURI(this.person.image.link).replace('(', '\\(').replace(')', '\\)');
    }
}
