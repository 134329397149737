import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { GoogleAnalyticsService } from './services/google-analytics.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

    constructor(
        private meta: Meta,
        private ga: GoogleAnalyticsService) {
    }

    title = 'unihack-website';

    public ngOnInit(): void {
        this.setMobileBrowserBarColors();
    }


    private setMobileBrowserBarColors() {
        const color = '#00092D';
        this.meta.updateTag({name: 'theme-color', content: color});
        this.meta.updateTag({name: 'sapplication-navbutton-color', content: color});
        this.meta.updateTag({name: 'apple-mobile-web-app-status-bar-style', content: color});
    }
}
