import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { OgMetaModel } from '../models/og-meta-model';
import { MetaTagsTitleService } from '../services/meta-tags-title.service';
import { Injectable } from '@angular/core';
import { ApiService } from '../services/api.service';

@Injectable()
export class PageResolver implements Resolve<any> {

    constructor(protected metaTags: MetaTagsTitleService,
                protected apiService: ApiService) {
    }


    private metas: { [key: string]: OgMetaModel } = {
        default: {
            title: 'UniHack',
            type: 'website',
            description: 'An international hackathon for university and high school students, with a focus on solving civic problems.',
            image: '/assets/images/og/og-image4.png',
        },
        'details': {
            title: 'Event Details - UniHack',
            type: 'website',
            description: 'Here you can find more details about UniHack.',
            image: '/assets/images/og/og-image4.png',
        },
        'about': {
            title: 'About us - UniHack',
            type: 'website',
            description: 'Here you can find more details about the organizers of UniHack.',
            image: '/assets/images/og/og-image4.png',
        },
        'privacy-policy': {
            title: 'Privacy policy - UniHack',
            type: 'website',
            description: 'Click here if you want to read our Privacy Policy.',
            image: '/assets/images/og/og-image4.png',
        },
    };

    private async loadMetaData(id: string): Promise<any> {
        let metaData = this.metas.default;
        try {
            const data = await this.apiService.getMetaData(id).toPromise();
            metaData = {
                title: data.title || metaData.title,
                type: data.type || metaData.type,
                description: data.description || metaData.description,
                image: data.image || metaData.image,
            };
        } catch (e) {
        }
        this.metaTags.updateTags(metaData);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
        return this.loadMetaData(route.data.ogMetaType);
    }
}
