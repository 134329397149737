<div id="project-ideas"></div>
<div class="inner-shadow-top component" *ngIf="ideas && ideas.length">
    <div class="container">
        <ng-container *ngFor="let item of ideas; index as i">
            <div class="row idea">
                <div class="col-12 col-md-8 offset-md-1">
                    <h1 class="titles"><span class="title-label">Proposed by:</span> {{item.partner}}</h1>
                    <dl class="idea-list">
                        <ng-container *ngFor="let idea of item.ideas">
                            <dt class="idea-title">{{idea.title}}</dt>
                            <dd class="idea-description" [innerHTML]="idea.description"></dd>
                        </ng-container>
                    </dl>
                </div>
            </div>
        </ng-container>
    </div>
</div>
