import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from '../home-page-banner/home-page-banner.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PreregisterService } from '../../../../services/preregister.service';
import { SnackMessageService } from '../../../../services/snack-message.service';

@Component({
    selector: 'app-preregister-popup',
    templateUrl: './preregister-popup.component.html',
    styleUrls: ['./preregister-popup.component.scss'],
})
export class PreregisterPopupComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private preregisterService: PreregisterService,
        public snack: SnackMessageService) {
    }
    public preregisterForm: FormGroup<{ needVisa: FormControl<boolean>; email: FormControl<string | null> }>;
    saving: boolean;
    private buildForm(): void {
        this.preregisterForm = new FormGroup({
            email: new FormControl<string>('', [Validators.required, Validators.email]),
            needVisa: new FormControl<boolean>(false),
        });
    }

    public ngOnInit () {
        this.buildForm();
    }
    async submit() {
        if (!this.preregisterForm.valid) {
            this.preregisterForm.markAllAsTouched();
            this.snack.display('Check the form before submitting.');
            return;
        }
        this.saving = true;
        try {
            await this.preregisterService.AddPreregister(this.preregisterForm.value);
            this.snack.display('Your have successfully preregistered!');
        } catch (e) {}
        this.saving = false;
    }
}
