<div class="inner-shadow-top component" *ngIf="projects && projects.length">
    <div class="container component">
        <div class="row mb-4">
            <div class="col-12 col-md-8 offset-md-1 titles">
                UniHack 2021 Projects
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-12 col-md-8 offset-md-1">
                <span class="finalists project-title mr-2">*</span> Finalist project
            </div>
        </div>
        <ng-container *ngFor="let item of projects; index as i">
            <div class="row idea">
                <div class="col-12 col-md-8 offset-md-1">
                    <div class="project-title">
                        <span>{{item.title}}</span>
                        <span *ngIf="item.isFinalist" class="finalists" title="Finalists">&nbsp;*</span>
                        <span class="repository"> - </span>
                        <a href="{{item.repository}}" target="_blank" class="repository">{{item.repository}}</a>
                    </div>
                    <div class="subtitles">Team: <span class="purple">{{item.team.name}}</span></div>
                    <div class="subtitles">Tracks:
                        <span class="purple" *ngFor="let track of item.tracks; index as i">
                            <span *ngIf="i > 0">&#8226;</span>
                            {{track.name}}
                        </span>
                    </div>
                    <p>{{item.description}}</p>
                </div>
            </div>
        </ng-container>
    </div>
</div>
