<div class="footer-main inner-shadow-top" id="footer">
    <div class="container component">
<!--        <div class="row">-->
<!--            <div class="col-12 newsletter-title text-center text-sm-left">-->
<!--                You know you want more info!-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="row top-padding">-->
<!--            <div class="col-12 newsletter-subtitle text-center text-sm-left">-->
<!--                Leave your email and we will send you the latest news.-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="row top-padding">-->
<!--            <div class="input-group col-12 col-sm-8 col-lg-6 ">-->
<!--                <input (keyup)="keyup($event)" [(ngModel)]="email" [disabled]="status =='saving'"-->
<!--                       class="email-input colored"-->
<!--                       placeholder="Your e-mail address" type="email">-->
<!--            </div>-->
<!--            <div class="col mt-3 mt-sm-0 flex-grow-1 flex-sm-grow-0 justify-content-end flex-column d-flex">-->
<!--                <div class="input-group justify-content-center justify-content-lg-start">-->
<!--                    <button (click)="send()" [disabled]="status == 'saving' || status=='saved' || !validateEmail(email)"-->
<!--                            class="btn btn-light btn-send "-->
<!--                            type="button">{{status == 'saving' ? 'Saving...' : status == 'saved' ? 'Subscribed!' : 'Subscribe'}}</button>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="row message" [hidden]="error">-->
<!--            <div class="col-12 text-center text-sm-left">-->
<!--                By subscribing you agree to receive emails from us and our-->
<!--                <a [routerLink]="'privacy-policy'"-->
<!--                   href="#pptop"-->
<!--                   pageScroll-->
<!--                   [pageScrollDuration]="500"-->
<!--                   [pageScrollAdjustHash]="true">Privacy Policy</a>.-->
<!--            </div>-->
<!--        </div>-->
        <div class="row error" [hidden]="!error">
            <div class="col-12 text-center text-sm-left">
                An error has occurred. Please try again later.
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center text-sm-right contact-email">
                <img src="../../../assets/images/icons/mail.svg">
                <a href="mailto:contact@unihack.eu">contact@unihack.eu</a>
            </div>
        </div>
        <div class="row bottom-section">
            <div class="col-8 left">
                <div class="links">
                    <a [routerLink]="'/faq'"
                       href="#faq"
                       pageScroll
                       [pageScrollDuration]="500"
                       [pageScrollAdjustHash]="true">FAQ</a>
                    <a href="https://static.mlh.io/docs/mlh-code-of-conduct.pdf" target="_blank">MLH Code of Conduct</a>
                    <a [routerLink]="'/privacy-policy'"
                       href="#pptop"
                       pageScroll
                       [pageScrollDuration]="500"
                       [pageScrollAdjustHash]="true">Privacy Policy</a>

                </div>
                <div class="links"></div>
                <div class="copyright">Copyright © Liga AC - All rights reserved.</div>
            </div>
            <div class="col-4 right">
                <div class="d-flex">
                    <a href="https://www.linkedin.com/company/unihack-eu/" class="social-icon mr-2" target="_blank">
                        <img class="linkedin" src="../../../assets/images/icons/linkedin.png">
                    </a>
                    <a href="https://www.instagram.com/unihack_ro/" class="social-icon mr-2" target="_blank">
                        <img class="logo-img" src="../../../assets/images/icons/LogoInstagram.svg">
                    </a>
                    <a href="https://facebook.com/unihack.eu" class="social-icon" target="_blank">
                        <img class="logo-img" src="../../../assets/images/icons/icon_fb.svg">
                    </a>
                </div>
                <div class="placeholder"></div>
                <div class="hashtags"><a href="https://fb.com/hashtag/unihack" target="_blank">#UniHack</a></div>
            </div>
        </div>
    </div>
</div>
