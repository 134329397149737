import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { HeaderMenuHeightService } from '../../services/header-menu-height.service';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { ApiService } from '../../services/api.service';
import { GoogleAnalyticsService } from '../../services/google-analytics.service';

@Component({
    selector: 'app-tracking-landing-page',
    templateUrl: './tracking-landing-page.component.html',
    styleUrls: ['./tracking-landing-page.component.scss'],
})
export class TrackingLandingPageComponent implements OnInit {

    constructor(public heightService: HeaderMenuHeightService,
                private route: ActivatedRoute,
                private router: Router,
                @Inject(PLATFORM_ID) private platformId: Object,
                private apiService: ApiService,
                private ga: GoogleAnalyticsService) {
        if (isPlatformBrowser(this.platformId)) {
            const rqp = this.route.snapshot.queryParams['r'];
            if (rqp) {
                this.ga.pageViewEvent(this.route.snapshot['_routerState'].url, true);
            }
            if (localStorage.getItem('tc')) {
                if (rqp === 'hp') {
                    this.router.navigate(['/']);
                }
                return;
            }
            const params = this.route.snapshot.params;
            const code = params['code'];
            if (!code) {
                return;
            }
            if (rqp === 'hp') {
                this.router.navigate(['/']);
            }
            this.apiService.addTrack(code).subscribe(response => {
                localStorage.setItem('tc', code);
            });
        }
    }

    ngOnInit() {
    }

}
