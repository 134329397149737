import { Component, ElementRef, Inject, PLATFORM_ID, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { FaqCategory } from '../../models/faq-model';
import { formatNumber, isPlatformBrowser } from '@angular/common';
import { HeaderMenuHeightService } from '../../services/header-menu-height.service';
import { startWith } from 'rxjs/internal/operators';

@Component({
    selector: 'app-faq-page',
    templateUrl: './faq-page.component.html',
    styleUrls: ['./faq-page.component.scss'],
})
export class FaqPageComponent {

    public get headerMenuHeight(): number {
        return this.hms.getHeight();
    }

    constructor(private apiService: ApiService,
                @Inject(PLATFORM_ID) private platformId: Object,
                private hms: HeaderMenuHeightService) {
        this.loadFaqs();
        if (isPlatformBrowser(this.platformId)) {
            window.addEventListener('scroll', () => {
                this.scrolled();
                this.resized();
            });
            window.addEventListener('resize', () => {
                this.resized();
            });
            this.scrolled();
            this.resized();
        }
    }

    private checkOffset: number;

    @ViewChildren('faqCategoryElements')
    public faqCategoryElements !: QueryList<ElementRef>;

    @ViewChild('banner', { static: true })
    public banner: ElementRef;

    @ViewChild('titleElement', { static: true })
    public titleElement: ElementRef;

    public categoryWrapper: { fixedTop?: boolean, fixedBottom?: boolean } = {};

    public faqCategories: FaqCategory[];

    public selectedIndex: any;

    public resized(): void {
        if (this.titleElement) {
            this.checkOffset = this.titleElement.nativeElement.clientHeight - 15;
        } else {
            this.checkOffset = 141;
        }
    }

    public scrolled(): void {
        if (!this.banner || window.scrollY < this.hms.getHeight() + this.checkOffset) {
            this.categoryWrapper.fixedTop = false;
            this.categoryWrapper.fixedBottom = false;
        } else {
            this.categoryWrapper.fixedTop = true;
            this.categoryWrapper.fixedBottom = false;
        }
         /// console.log(window.scrollY);
    }

    /// TODO: auto select category on scroll
    public auto_select_category_on_scroll(index: number): boolean {
        const element = this.faqCategoryElements.toArray()[index];
        const nextElement = this.faqCategoryElements.toArray()[index + 1];
        if (element) {
            const top = element.nativeElement.offsetTop - this.headerMenuHeight - 150;
            if (nextElement) {
                const topNextElement = nextElement.nativeElement.offsetTop - this.headerMenuHeight - 150;
                const delta = topNextElement - top;
                if (window.scrollY >= top && window.scrollY <= top + delta) {
                    this.faqCategories[index].selected = true;
                    return true;
                } else {
                    return false;
                }
            } else {
                if (window.scrollY >= top) {
                    this.faqCategories[index].selected = true;
                    return true;
                } else {
                    return false;
                }
            }
        }
        for (const f of this.faqCategories) {
            if (f.selected) {
                f.selected = false;
            }
        }
        this.faqCategories[index].selected = true;
        return true;
    }

    public selectCategory(index: number) {
        const element = this.faqCategoryElements.toArray()[index];
        if (element) {
            const top = element.nativeElement.offsetTop;
            if (index === 0) {
                window.scrollTo({
                    top: top - this.headerMenuHeight - 150,
                    behavior: 'smooth',
                });
            } else {
                window.scrollTo({
                    top: top - this.headerMenuHeight - 50,
                    behavior: 'smooth',
                });
            }

        }
        for (const f of this.faqCategories) {
            if (f.selected) {
                f.selected = false;
            }
        }
        this.faqCategories[index].selected = true;
    }

    public selectChanged() {
        this.selectCategory(this.selectedIndex);
        /// this.auto_select_category_on_scroll(this.selectedIndex);
    }

    private loadFaqs() {
        this.apiService.getFaqs().subscribe(data => {
            this.faqCategories = data;
            if (!this.faqCategories.length) {
                return;
            }
            this.faqCategories[0].selected = true;
            this.selectedIndex = 0;
        }, error1 => {
            console.log('Could not get faqs: ', error1);
        });
    }
}
