import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PersonModel } from '../../../../models/person.model';

@Component({
    selector: 'app-people-list',
    templateUrl: './people-list.component.html',
    styleUrls: ['./people-list.component.scss'],
})
export class PeopleListComponent {
    public people: PersonModel[];

    constructor(private actr: ActivatedRoute) {
        this.people = this.actr.snapshot.data.people;
    }
}
