<div class="banner inner-shadow-top" id="pitop">
    <div class="container full-height-vh vertical-align banner-background-container"
         [ngStyle]="{'padding-top': heightService.getHeightPx}">
        <div class="banner-background"></div>
        <div></div>
        <div class="row offset-sm-1">
            <div class="col-12 col-md-7">
                <div class="titles">Project Ideas</div>
            </div>
            <div class="col-12 col-md-7 justify-content-center">
                <div class="description text-md-left d-block">
                    <p>
                        Short on ideas for your project? That stroke of genius for a brilliant idea is nowhere to be found?
                    </p>
                    <p>
                        Well, we're here to help! Check out the proposals our sponsors and partners came up with.
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-center">
                <div class="arrow">
                    <a
                            [routerLink]="'/project-ideas'"
                            pageScroll
                            [pageScrollOffset]="66"
                            [pageScrollAdjustHash]="true"
                            [pageScrollDuration]="500"
                            href="#project-ideas">
                        <div class="text-find-out mt-4">See the ideas!</div>
                        <img src="/assets/images/arrow.svg"/>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
