import { Component, Input, OnInit } from '@angular/core';
import { HeaderMenuHeightService } from '../../../../services/header-menu-height.service';
import { GlobalSettings } from '../../../../models/settings.model';

@Component({
    selector: 'app-details-banner',
    templateUrl: './event-details-banner.component.html',
    styleUrls: ['./event-details-banner.component.scss'],
})
export class EventDetailsBannerComponent implements OnInit {
    @Input()
    public settings: GlobalSettings;
    constructor(public heightService: HeaderMenuHeightService) {
    }

    ngOnInit() {
    }
}
